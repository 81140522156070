/*Print styles*/
@media print {
  header .donate-block{
   display: none;
  }
  .slick-prev, .slick-next{
    display: none;
  }
  .slick-dots{
  	display: none;
  }
  .social-icons{
  	display: none;
  }
  .col-twitter img{
		display: none;
	}
	nav,
	.main-slider,
	.news-block img{
		display: none;
	}
	.news-block p{
		padding-left: 0!important;
	}
}